@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  @apply bg-white text-black-700 text-lg;
}

#__next {
  @apply text-base;
}

@layer utilities {
  .hidden-scrollbar {
    -ms-overflow-style: none; /* IE, Edge 対応 */
    scrollbar-width: none; /* Firefox 対応 */
  }
  .hidden-scrollbar::-webkit-scrollbar {
    /* Chrome, Safari 対応 */
    display: none;
  }
}
